<template>
  <vs-popup class="sm:popup-w-80" :title="`Process Payment (${invoice ? 'INVOICE' : 'DIRECT PAYMENT'})`" :active="isActive" v-on:update:active="emitModalIsActive">
    <div :class="{'pointer-events-none opacity-50': loading.getTransactions}">
      <div class="flex flex-col" style="height: calc(100vh - 140px);">
        <!--CONTENT-->
        <div class="flex flex-col flex-1">
          <div class="flex flex-none">
            <ValidationErrors :errors="errors"/>
          </div>

          <div class="vx-row flex flex-none flex-wrap">
            <!--form-->
            <div class="vx-col w-full lg:w-4/12">
              <div class="flex flex-col flex-none">
                <vs-divider class="mt-0 mb-8">Payment Information</vs-divider>
                <div class="flex items-center mb-1">
                  <label class="text-xs w-32">No. Invoice</label>
                  <vs-input class="w-full" :value="invoice ? invoice.no_invoice : 'DIRECT PAYMENT'" readonly/>
                </div>
                <div class="flex items-center">
                  <label class="text-xs w-32">Grand Total Balance</label>
                  <v-money class="w-full" :value="transactionsSummary.grandTotalBalance" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Tgl Bayar</label>
                  <flat-pickr class="w-full" v-model="data.tgl_bayar"></flat-pickr>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Cara Bayar</label>
                  <vs-select class="w-full" v-model="data.id_cara_bayar" @input="onChangeCaraBayar($event)">
                    <vs-select-item v-for="(it, index) in caraBayars" :key="index" :value="it.id" :text="it.nama"/>
                  </vs-select>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">No. Cek/Giro</label>
                  <vs-input class="w-full" v-model="data.no_cek_giro"/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Tgl. Cek/Giro</label>
                  <flat-pickr class="w-full" v-model="data.tgl_cek_giro"></flat-pickr>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Keterangan</label>
                  <vs-input class="w-full" v-model="data.keterangan"/>
                </div>
              </div>
            </div>

            <!--journals-->
            <div class="vx-col w-full lg:w-8/12">
              <div class="flex flex-col flex-grow">
                <vs-divider class="mt-0 mb-0">Journals</vs-divider>
                <div>
                  <vs-button class="px-2 py-1 mb-1 text-xs text-center rounded-sm" color="primary" @click="addJournal">+</vs-button>
                </div>
                <div class="overflow-auto d-theme-dark-bg border border-solid d-theme-border-grey-light" style="height: 20.7rem;">
                  <table class="table-fixed table-sticky border-collapse w-full">
                    <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                      <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                        <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Account</th>
                        <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-72">Keterangan</th>
                        <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Debet</th>
                        <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Kredit</th>
                      </tr>
                    </thead>
                    <tbody class="font-medium">
                    <tr v-for="(item, index) in data.journals" :key="index" class="cursor-pointer hover:font-medium">
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-left">
                        <div class="flex select-none">
                          <span class="text-danger mr-3" @click="removeJournal(item)">x</span>
                          <span class="underline" @click="showModalCoa(item)">
                              {{ item.id_coa ? `${item.kode_coa} - ${item.nama_coa}` : 'Choose' }}
                          </span>
                        </div>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-left">
                        <div>
                          <input type="text" class="border-none w-full text-sm" v-model="item.keterangan"/>
                        </div>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-right">
                        <div v-if="item.source !== 'TRANSACTION'">
                          <v-money class="w-full" theme="theme2" v-model="item.debet"/>
                        </div>
                        <span v-else>{{ item.debet | idr }}</span>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-right">
                        <div v-if="item.source !== 'TRANSACTION'">
                          <v-money class="w-full" theme="theme2" v-model="item.kredit"/>
                        </div>
                        <span v-else>{{ item.kredit | idr }}</span>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap" :class="idr(journalsSummary.grandTotalDebet) === idr(journalsSummary.grandTotalKredit) ? 'text-success' : 'text-danger'">{{ journalsSummary.grandTotalDebet | idr }}</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap" :class="idr(journalsSummary.grandTotalDebet) === idr(journalsSummary.grandTotalKredit) ? 'text-success' : 'text-danger'">{{ journalsSummary.grandTotalKredit | idr }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="vx-row flex flex-none flex-wrap">
            <!--person-->
            <div class="vx-col w-full lg:w-4/12">
              <div class="flex flex-col flex-none">
                <vs-divider class="mt-3">Customer Information</vs-divider>
                <div class="flex items-center mb-1">
                  <label class="text-xs w-32">Nama Customer</label>
                  <vs-input class="w-full" :value="customerInformation.nama_customer || '-'" readonly/>
                </div>
                <div class="flex items-center">
                  <label class="text-xs w-32">Telp Customer</label>
                  <vs-input class="w-full" :value="customerInformation.telp_customer || '-'" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Bank Rekening</label>
                  <vs-input class="w-full" :value="customerInformation.bank_rekening_customer || '-'" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">No. Rekening</label>
                  <vs-input class="w-full" :value="customerInformation.no_rekening_customer || '-'" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">A/N Rekening</label>
                  <vs-input class="w-full" :value="customerInformation.an_rekening_customer || '-'" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Bukti Bayar</label>
                  <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="data.buktiTransferTemp" @change="onSelectedBuktiTransfer"/>
                </div>
              </div>
            </div>

            <!--transactions-->
            <div class="vx-col w-full lg:w-8/12">
              <div class="flex flex-col flex-auto">
                <vs-divider class="mt-3">
                  <div class="flex space-x-2">
                    <span>Transactions</span>
                    <QueryEditor v-if="$can('view_query')" code="AR_PROCESS_PAYMENT_TRANSACTIONS"/>
                  </div>
                </vs-divider>
                <div class="overflow-auto d-theme-dark-bg border border-solid d-theme-border-grey-light" style="height: 18rem;">
                  <table class="w-full table-auto table-sticky border-collapse">
                    <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Trx No</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Date</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Remark</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Total</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Total Paid</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Last Balance</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap w-32">Jml Bayar</th>
                    </tr>
                    </thead>
                    <tbody class="font-medium">
                    <tr v-for="(item, index) in data.transactions" :key="index">
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.no_trx }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">{{ item.date }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.remark || '-' }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ (item.total || 0) | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ (item.total_paid || 0) | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right" :class="{'text-danger': item.jml_bayar > item.total_balance}">{{ (item.total_balance || 0) | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right" :class="{'text-danger': item.jml_bayar > item.total_balance}">
                        <v-money class="w-full" theme="theme2" v-model="item.jml_bayar" @input="onChangeJmlBayarTransactionItem(item)"/>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ transactionsSummary.grandTotal | idr }}</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ transactionsSummary.grandTotalPaid | idr }}</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ transactionsSummary.grandTotalBalance | idr }}</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap" :class="transactionsSummary.grandTotalJmlBayar <= transactionsSummary.grandTotalBalance ? 'text-success' : 'text-danger'">{{ transactionsSummary.grandTotalJmlBayar | idr }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--FOOTER-->
        <div class="flex flex-none justify-end">
          <div class="flex mt-2">
            <vs-button class="mr-3" size="small" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button size="small" :disabled="loading.processPayment || isCompressingBuktiTransfer" @click="confirmProcessPayment">
              <span v-if="loading.processPayment" class="animate-pulse">Menyimpan...</span>
              <span v-if="!loading.processPayment">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih COA"
              :active.sync="modalCoa.active">
      <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
    </vs-popup>

  </vs-popup>
</template>

<script>
import ArRepository from '@/repositories/accounting/ar-repository'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ProcessPayment',
  props: {
    isActive: { required: true, type: Boolean },
    invoice: { required: false, type: Object, default: null },
    transactions: { required: false, type: Array, default: null }
  },
  components: {
    ValidationErrors,
    VMoney,
    flatPickr,
    Coa: () => import('@/views/pages/master/coa/Coa'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  mounted () {
    this.getAllCaraBayar()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getTransactions()
      } else {
        this.resetData()
      }
    }
  },
  computed: {
    customerInformation () {
      if (this.data.transactions.length < 1) return {}
      const data = this.data.transactions[0]
      return {
        nama_customer: data.nama_customer,
        telp_customer: data.telp_customer,
        bank_rekening_customer: data.bank_rekening_customer,
        no_rekening_customer: data.no_rekening_customer,
        an_rekening_customer: data.an_rekening_customer
      }
    },
    journalsSummary () {
      return {
        grandTotalDebet: _.sumBy(this.data.journals, item => parseFloat(item.debet) || 0),
        grandTotalKredit: _.sumBy(this.data.journals, item => parseFloat(item.kredit) || 0)
      }
    },
    transactionsSummary () {
      return {
        grandTotal: _.sumBy(this.data.transactions, item => parseFloat(item.total) || 0),
        grandTotalPaid: _.sumBy(this.data.transactions, item => parseFloat(item.total_paid) || 0),
        grandTotalBalance: _.sumBy(this.data.transactions, item => parseFloat(item.total_balance) || 0),
        grandTotalJmlBayar: _.sumBy(this.data.transactions, item => parseFloat(item.jml_bayar) || 0)
      }
    },
    posisiCoaTransaksi () {
      if (this.data.transactions.length === 0) return null
      return this.data.transactions[0].posisi_coa
    },
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  data () {
    return {
      errors: null,
      isCompressingBuktiTransfer: false,
      loading: {
        processPayment: false,
        getTransactions: false
      },
      modalCoa: {
        active: false,
        uuid: false
      },
      data: {
        id_cara_bayar: null,
        tgl_bayar: moment().format('YYYY-MM-DD'),
        grand_total_balance: 0,
        journals: [],
        transactions: [],
        buktiTransfer: [],
        keterangan: null
      },
      caraBayars: []
    }
  },
  methods: {
    addJournal () {
      this.data.journals.push({
        source: 'MANUAL',
        uuid: uuid(),
        id_coa: null,
        kode_coa: null,
        nama_coa: null,
        keterangan: null,
        debet: 0,
        kredit: 0
      })
    },

    removeJournal (row) {
      const index = _.findIndex(this.data.journals, item => item.uuid === row.uuid)
      this.data.journals.splice(index, 1)
    },

    showModalCoa (item) {
      this.modalCoa.active = true
      this.modalCoa.uuid = item.uuid
    },

    onSelectedModalCoa (coa) {
      const index = _.findIndex(this.data.journals, item => item.uuid === this.modalCoa.uuid)
      this.data.journals[index].id_coa = coa.id
      this.data.journals[index].kode_coa = coa.kode
      this.data.journals[index].nama_coa = coa.nama
      this.modalCoa.active = false
    },

    getTransactions () {
      this.loading.getTransactions = true

      const transactions = this.transactions || []
      const params = {
        tipe_jurnal: this.transactions ? this.transactions[0].tipe_jurnal : null,
        id_invoice: this.invoice ? this.invoice.id : null,
        trx_ids: _.map(transactions, trx => (trx.is_group_by_trx_header ? trx.id_trx_h : trx.id_trx_d))
      }
      ArRepository.getProcessPaymentTransactions(params)
        .then(response => {
          this.onTransactionsDataReady(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getTransactions = false
        })
    },

    onTransactionsDataReady (transactions) {
      if (transactions.length < 1) {
        return this.notifyError('Tidak ada data transaksi ditemukan.')
      }

      this.data.transactions = _.map(transactions, trx => {
        trx.uuid = uuid()
        trx.jml_bayar = trx.total_balance
        return trx
      })

      if (!this.data.keterangan) {
        this.data.keterangan = `Pembayaran Piutang ${this.data.transactions[0].group}`
      }
      this.addTransactionsToJournals()
    },

    async onSelectedBuktiTransfer (event) {
      const files = event.target.files
      const items = []
      this.isCompressingBuktiTransfer = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingBuktiTransfer = false
      this.data.buktiTransfer = items
    },

    onChangeCaraBayar (idCaraBayar) {
      const caraBayar = _.find(this.caraBayars, item => item.id === idCaraBayar)
      const journal = {
        source: 'CARA_BAYAR',
        uuid: uuid(),
        id_coa: caraBayar.id_coa,
        kode_coa: caraBayar.kode_coa,
        nama_coa: caraBayar.nama_coa,
        keterangan: `${this.data.keterangan} - ${caraBayar.nama}`,
        debet: this.posisiCoaTransaksi === 'KREDIT' ? this.transactionsSummary.grandTotalJmlBayar : 0,
        kredit: this.posisiCoaTransaksi === 'DEBET' ? this.transactionsSummary.grandTotalJmlBayar : 0
      }
      if (caraBayar) {
        const index = _.findIndex(this.data.journals, item => item.source === 'CARA_BAYAR')
        if (index === -1) {
          this.data.journals.push(journal)
        } else {
          this.data.journals[index] = journal
        }
      }
    },

    onChangeJmlBayarTransactionItem (transaction) {
      const newAmount = _.sumBy(_.filter(this.data.transactions, item => item.id_coa === transaction.id_coa), it => it.jml_bayar)
      const journalIndex = _.findIndex(this.data.journals, item => item.id_coa === transaction.id_coa)
      if (this.posisiCoaTransaksi === 'DEBET') {
        this.data.journals[journalIndex].debet = newAmount
      } else {
        this.data.journals[journalIndex].kredit = newAmount
      }

      // update cara bayar value
      const index = _.findIndex(this.data.journals, item => item.source === 'CARA_BAYAR')
      if (index !== -1) {
        if (this.posisiCoaTransaksi === 'DEBET') {
          this.data.journals[index].kredit = this.transactionsSummary.grandTotalJmlBayar
        } else {
          this.data.journals[index].debet = this.transactionsSummary.grandTotalJmlBayar
        }
      }
    },

    addTransactionsToJournals () {
      _.each(this.data.transactions, item => {
        const index = _.findIndex(this.data.journals, journal => journal.id_coa === item.id_coa)
        const isSameCoa = index !== -1
        if (isSameCoa) {
          this.data.journals[index].debet += (this.posisiCoaTransaksi === 'DEBET' ? item.jml_bayar : 0)
          this.data.journals[index].kredit += (this.posisiCoaTransaksi === 'KREDIT' ? item.jml_bayar : 0)
        } else {
          this.data.journals.push({
            source: 'TRANSACTION',
            id_coa: item.id_coa,
            kode_coa: item.kode_coa,
            nama_coa: item.nama_coa,
            keterangan: item.keterangan_coa_jurnal,
            debet: this.posisiCoaTransaksi === 'DEBET' ? item.jml_bayar : 0,
            kredit: this.posisiCoaTransaksi === 'KREDIT' ? item.jml_bayar : 0
          })
        }
      })
    },

    getAllCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'AR' })
      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    confirmProcessPayment () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: 'Yakin ingin melanjutkan?',
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => {
          this.processPayment()
        }
      })
    },

    processPayment () {
      this.loading.processPayment = true

      const params = convertToFormData(this.buildParams())
      ArRepository.processPayment(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.processPayment = false
        })
    },

    buildParams () {
      const transactions = _.filter(this.data.transactions, item => item.jml_bayar > 0)
      return {
        tipe_jurnal: this.data.transactions[0].tipe_jurnal,
        id_proyek: this.data.transactions[0].id_proyek,
        id_invoice: this.invoice ? this.invoice.id : null,
        tgl_bayar: this.data.tgl_bayar,
        id_cara_bayar: this.data.id_cara_bayar,
        no_cek_giro: this.data.no_cek_giro,
        tgl_cek_giro: this.data.tgl_cek_giro,
        keterangan: this.data.keterangan,
        grand_total_balance: this.transactionsSummary.grandTotalBalance,
        grand_total_bayar: this.transactionsSummary.grandTotalJmlBayar,
        bukti_transfer: this.data.buktiTransfer,
        journals: this.data.journals,
        transactions: _.map(transactions, item => {
          return {
            id_trx: item.id_trx,
            no_trx: item.no_trx,
            remark: item.remark,
            total_balance: item.total_balance,
            jml_bayar: item.jml_bayar
          }
        })
      }
    },

    onSuccess () {
      this.notifySuccess('Process payment sukses')
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.resetData()
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
